<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Add the "{{drinkModel.name}}" Recipe</v-toolbar-title>
        </v-toolbar>

        <!-- <v-img :src="`../img/placeholder-image.png`"></v-img> -->

        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img 
              max-height="350" contain
              :src="uploadedRecipeImageUrl === '' ? '../img/placeholder-image.png' : uploadedRecipeImageUrl"
            >
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="info"
                >
                  <v-btn 
                    :loading="selectingImage"
                    color="primary" 
                    class="text-none" 
                    rounded 
                    depressed
                    @click="onUploadImageClick"
                  >
                    <v-icon left>mdi-cloud-upload</v-icon>Upload
                  </v-btn>
                  <input 
                    ref="uploader" 
                    class="d-none" 
                    type="file" 
                    accept="image/*" 
                    @change="onFileChanged"
                  >
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
        
        <v-card-text>
          <v-row justify="center">
            <v-col cols="11">
              <v-text-field persistent-hint hint="Make your own recipe!"
                v-model="drinkModel.name"
                label="Custom Recipe"
                :rules="recipeNameRules"
                clear-icon="mdi-close-circle"
                @click:clear="clearDrinkModel()"
                clearable 
              ></v-text-field>
            </v-col>
          </v-row> 
          
          <!-- Ingredients -->          
          <v-form ref="newRecipe">
            <v-row class="align-center">
              <v-col>
                <v-subheader>Ingredients</v-subheader>
              </v-col>
              <v-col class="text-right" v-if="!addIngredientBox">
                <v-btn color="primary" small outlined @click="addIngredientBox = !addIngredientBox">
                {{addIngredientBox ? 'Done' : `Add Ingred`}}
                <v-icon right>
                  {{addIngredientBox ? 'mdi-minus' : 'mdi-plus'}}
                </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            
            <!-- Add Ingredient Area -->            
            <v-divider inset></v-divider>
            <template v-if="addIngredientBox">
              <v-row align="center" justify="end">
                
                <!-- Quantity Input -->                
                <v-col cols="12" sm="4" md="4">
                  <qty-measurement-picker
                    :qty.sync="ingredientToAdd.qty"
                    :measurement.sync="ingredientToAdd.measurement"
                  >  
                  </qty-measurement-picker>
                </v-col> 
                
                <!-- Ingredient Input -->   
                <v-col cols="12" sm="5" md="5">  
                  <v-combobox
                    placeholder="What's the ingredient?"
                    chips small-chips
                    label="Ingredient"
                    :items="storeInventory"
                    item-text="name"
                    return-object single-line
                    v-model="ingredientToAdd.type"
                    :search-input.sync="ingredientSearch"
                    hint="Press enter/return to pick a value not shown."
                    @change="addNewIngredientChanged"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <span class="subheading">Hit <kbd>Enter</kbd>, <kbd>⏎</kbd>, or <kbd>return</kbd> to add <v-chip color="success" small>{{ingredientSearch}}</v-chip></span>
                      </v-list-item>
                    </template>
                    
                    <!-- Custom Ingredient Type Selector --> 
                    <template v-slot:append-outer>
                      <v-menu rounded="b-xl" offset-y v-model="newIngredType">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn color="info" small text v-bind="attrs" v-on="on">{{ingredientToAddType}}
                            <v-icon>{{newIngredType ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                          </v-btn>
                        </template>  
                        <v-list>
                          <v-list-item-group v-model="ingredientToAddType" mandatory>
                            <v-list-item v-for="ingred in ingredientTypes" :key="ingred.value" :value="ingred.name">
                              <v-list-item-title v-text="ingred.name"></v-list-item-title> 
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>  
                    </template>

                    <template v-slot:selection="data">
                      <v-chip :color="getIngredientColor(data.item.type)">
                        <v-avatar color="grey lighten-4" left>
                          <span>{{data.item.icon || '🧊'}}</span>
                        </v-avatar>
                        {{typeof data.item == 'string' ? data.item : data.item.name}}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else-if="data.item.ingredientType == 'inventory'">
                        <v-list-item-avatar>
                          <v-avatar color="grey lighten-4">
                            <span class="headline">{{getEmoji(data.item.type)}}</span>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="data.item.name"></v-list-item-title>
                          <v-list-item-subtitle v-text="data.item.subtitle"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-else>
                        <!-- If it's a generic item, and note an inventory item  -->
                        <v-list-item-avatar>
                          <v-avatar color="grey lighten-4">
                            <span class="headline">{{data.item.icon != null ? data.item.icon : '🧊'}}</span>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    
                  </v-combobox>
                </v-col>
                
                <v-col cols="12" sm="2" md="2">
                  <v-btn block color="success" @click="addNewIngredient">
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Add
                  </v-btn>
                </v-col> 
              </v-row>
              <v-row dense>
                <v-col class="text-right pt-0" v-if="addIngredientBox">
                  <v-btn color="primary" small outlined @click="addIngredientBox = !addIngredientBox">
                  {{addIngredientBox ? 'Done' : `Add Ingred`}}
                  <v-icon right>
                    {{addIngredientBox ? 'mdi-minus' : 'mdi-plus'}}
                  </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            
            <!-- List of Ingredients -->            
            <v-row justify="center" v-if="recipe.ingredients.length >= 1"> <!-- drinkSearchModel != null -->
              <v-col cols="10">
                <v-card tile>
                  <v-list>
                    <v-list-item v-for="(item, index) in recipe.ingredients" :key="index">
                      <v-list-item-content>
                        <span>{{ingredientMeasurement(item)}}: <v-chip :color="getIngredientColor(item.type.type)"> <v-avatar color="grey lighten-4" left><span>{{item.type.icon}}</span></v-avatar>{{ingredientDisplayName(item)}}</v-chip></span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="removeIngredient(index)">
                          <v-icon color="grey lighten-1">mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
            
            <!-- Steps -->            
            <v-row dense class="mt-4 align-center">
              <v-col>
                <v-subheader>Steps</v-subheader>
              </v-col>
              <v-col class="text-right" v-if="!addStepBox">
                <v-btn small color="primary" outlined @click="addStepBox = !addStepBox">
                  {{addStepBox ? 'Done' : `Add a Step`}}
                  <v-icon right>
                    {{addStepBox ? 'mdi-minus' : 'mdi-plus'}}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider inset></v-divider>
            
            <template v-if="addStepBox">
              <v-row dense justify="end" align="center">
                <v-col cols="12" sm="9" md="9">
                  <v-text-field 
                    v-model="newStepText"
                    :label="`Step ${recipe.steps.length+1}:`"
                    hint="What step is next?"
                    @keydown.enter.prevent="addNewStep"
                  >
                  </v-text-field>
                </v-col>
                
                <v-col cols="12" sm="2" md="2">
                  <v-btn block color="success" @click="addNewStep">
                    <v-icon left>mdi-plus</v-icon>
                    Add
                  </v-btn>
                </v-col>

                <v-col cols="12" class="text-right" v-if="addStepBox">
                  <v-btn small color="primary" outlined @click="addStepBox = !addStepBox">
                    {{addStepBox ? 'Done' : `Add a Step`}}
                    <v-icon right>
                      {{addStepBox ? 'mdi-minus' : 'mdi-plus'}}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            
            <v-row justify="center" v-if="recipe.steps.length >= 1"> <!-- drinkSearchModel != null --> 
              <v-col cols="10">
                <v-card tile>
                  <v-list>
                    <v-list-item v-for="(step, index) in recipe.steps" :key="index">
                      <v-list-item-content>
                        {{`Step ${index+1}:  ${step}`}}
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="removeInstruction(index)">
                          <v-icon color="grey lighten-1">mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>

            <v-subheader class="mt-4">Private</v-subheader>
            <v-divider inset></v-divider>
            <v-row class="pl-4">
              <v-col>
                <v-switch @change="updateRecipePublicity" v-model="recipe.private" :label="`${recipe.private ? 'Yes' : 'No'}`"></v-switch>
              </v-col>
            </v-row>
          </v-form>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="saveRecipe">
            Add Recipe
            <v-icon right>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { Storage } from '../firebase/storage.js';
import { mapGetters, mapState } from 'vuex'
  import cocktaildb from '../api/cocktaildb'
  
  export default {
    name: 'AddRecipe',
    data: () => ({
      addNewMeasurement: false, 
      drinkModelName: '', 
      drinkModel: {
        name: '',
        ingredients: [], 
        instructions: [],
      }, 
      drinkSearchModel: null,
      
      drinkEntries: [],
      isLoading: false,
      search: null,

      selectingImage: false,
      uploadingImage: false,
      uploadedRecipeImage: null,
      uploadedRecipeImageUrl: '',
      uploadedImageDownloadURL: '',
      
      recipe: {
        name: '', 
        ingredients: [], 
        steps: [], 
        tools: [],
        public: true, 
        private: false,
        thumbnail: '',
      }, 
      
      newIngredQty: false, 
      newIngredType: false,
      
      addIngredientBox: false, 
      ingredientToAdd: {
        customMeasurement: '',
        measurement: 'mL', 
        qty: '',
        type: ''
      },
      ingredientToAddType: 'Other 🧊',
      ingredientSearch: '',
      measurements: [
        "oz", "dash", "cup(s)", "can(s)", "twist(s)"
      ],
      
      addStepBox: false,
      newStepText: '',
      
      recipeNameRules: [
        v => !!v || 'Name is required.',
        v => (v && v.length >= 3) || 'Length must be greater than 3 characters.'
      ], 
      
      ingredientTypes: [
        {name: 'Whiskey 🥃', value: 'whiskey'},
        {name: 'Brandy 🍷', value: 'brandy'}, 
        {name: 'Vodka 🥔', value: 'vodka'},
        {name: 'Rum 🍹', value: 'rum'},
        {name: 'Gin 🍸', value: 'gin'},
        {name: 'Tequila 🌵', value: 'tequila'},
        {name: 'Liqueur 🥂', value: 'liqueur'},
        {name: 'Other 🧊', value: 'other'},
      ],
      
    }), 
    computed: {
      ...mapState({
        user: 'user'
      }),
      ...mapGetters({
        storeInventory: 'getPossibleIngredients',
      }), 
      drinkItems(){
        return this.drinkEntries.map( entry => {

          return entry;
        })
      }
    },
    watch: {
      search(val){
        if(this.drinkItems.length > 0 ) return
        if(this.search <= 1) return
        if(this.isLoading) return 
        this.isLoading = true
        this.fetchDebounced()
      }
    },
    methods: {
      onUploadImageClick(){
        window.addEventListener('focus', () => {
          this.isSelecting = false;
        }, { once: true })

        this.$refs.uploader.click()
      },
      onFileChanged(e){
        this.uploadedRecipeImage = e.target.files[0];
        this.uploadedRecipeImageUrl = URL.createObjectURL(e.target.files[0]);
      },
      updateRecipePublicity(value){
        // console.log(value)
        this.recipe.public = !value;
      },
      clearDrinkModel(){
        console.log('In clear')
        this.drinkModel.name = " ";
      },
      ingredientToAddName(ingredientValue){
        var returnIngred = {}
        returnIngred = this.ingredientTypes.find(item => item.value == ingredientValue)
        return returnIngred.name;
      },
      ingredientMeasurement(item){
        if(typeof item.type === 'object'){
          if(item.type.ingredientType == 'recipe' || item.type.ingredientType == 'inventory' || item.type.ingredientType == 'generic'){
            return `${item.qty} ${item.measurement} ` // Removed: ${item.type.name}
          } else {
            return item.qty == null ? `${item.measurement} ` : `${item.qty} ${item.measurement} ` //First Ternary: ${typeof item.type === 'object' ? item.type.type : item.type} Second Ternary:  ${typeof item.type === 'object' ? item.type.type : item.type}
          }
        }
      },
      ingredientDisplayName(item){
        if(typeof item.type === 'object'){
          if(item.type.ingredientType == 'recipe' || item.type.ingredientType == 'inventory' || item.type.ingredientType == 'generic'){
            return `${item.type.name}` // Removed: 
          } else {
            return item.qty == null ? `${typeof item.type === 'object' ? item.type.type : item.type}` : `${typeof item.type === 'object' ? item.type.type : item.type}` //First Ternary:  Second Ternary:  
          }
        }
      },
      addNewIngredientChanged(value){
        console.log(value);
        var searchTerm = ''
        
        if(value == null){
          return;
        }

        if(typeof value === 'object'){
          searchTerm = `${value.name} ${value.type}`
        } else {
          searchTerm = value
        }
        
        if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)\b/i.test(searchTerm)){
          this.setNewIngredientType('Whiskey 🥃')
        } 
        
        // Brandy 🍷
        else if(/br\w*nd\w*y\b/i.test(searchTerm)){
          this.setNewIngredientType('Brandy 🍷')
        }
        
        // Vodka 🥔
        else if(/(vodka)\b/i.test(searchTerm)){
          this.setNewIngredientType('Vodka 🥔')
        }
        
        // Rum 🍹
        else if(/\b(rh*ums?)\b/i.test(searchTerm)){
          this.setNewIngredientType('Rum 🍹')
        }
        
        // Gin 🍸
        else if(/\b(gin)\b/i.test(searchTerm)){
          this.setNewIngredientType('Gin 🍸')
        }
        
        // Tequila 🌵
        else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(searchTerm)){
          this.setNewIngredientType('Tequila 🌵')
        }
        
        // Liqueur 🥂'
        else if(/\b(liq|lic)\w*r\b/i.test(searchTerm)){
          this.setNewIngredientType('Liqueur 🥂');
        } 
        
        else {
          this.setNewIngredientType('Other 🧊');
        }
        
        console.log(value);
      },
      setNewIngredientType(ingredient){
        this.ingredientToAddType = ingredient;
      },
      removeIngredient(index){
        this.recipe.ingredients.splice(index, 1)
      },
      removeInstruction(index){
        this.recipe.steps.splice(index, 1)
      },
      fetchDebounced(){
        clearTimeout(this._searchTimerId)
        
        this._searchTimeId = setTimeout(() => {
          this.fetchDrinks()
        }, 1000)
      },
      fetchDrinks(){
        cocktaildb.searchForDrink(this.search, (recipes) => {
          // If failure 
          if(!recipes){
            this.$emit('showAlert', {text: 'Oops, something went wrong 😟.', type: 'error'}); 
            return;
          } 
          
          this.drinkEntries = recipes;
          this.isLoading = false
          
        })
      },
      addNewStep(){
        if(this.newStepText.length == 0){
          return;
        }
        
        this.recipe.steps.push(this.newStepText);
        
        this.newStepText = '';
      }, 
      addNewIngredient(){
        // Current issue where custom ingredients aren't being added correctly
        if(typeof this.ingredientToAdd.type === 'string'){
          
          var newIngredientType = {
            measurement: this.ingredientToAdd.measurement == 5 ? this.ingredientToAdd.customMeasurement : this.ingredientToAdd.measurement,
            qty: this.ingredientToAdd.qty,
            type: {
              name: `${this.ingredientToAdd.type}`,
              icon: '🛒',
              ingredientType: 'generic',
              type: this.ingredientToAddType, 
            }
          }
          
          // Push formatted ingredient onto recipe.ingredients
          console.log(newIngredientType);
          this.recipe.ingredients.push(newIngredientType);
          
        } else {
          // If there's not a string, it's a returned object so just push it
          // console.log(JSON.stringify(this.ingredientToAdd));
          let newIngredient = {
            measurement: this.ingredientToAdd.measurement == 5 ? this.ingredientToAdd.customMeasurement : this.ingredientToAdd.measurement,
            qty: this.ingredientToAdd.qty,
            type: this.ingredientToAdd.type,
          };
          
          this.recipe.ingredients.push(newIngredient); 
        }
        
        // now reset 
        this.ingredientToAdd = { 
          qty: 0, 
          measurement: 'oz', 
          type: '', 
          customMeasurement: ''
        };
        this.ingredientToAddType = 'other';
        this.addNewMeasurement = false;
      }, 
      saveRecipe(){
        this.$refs.newRecipe.validate();

        // Upload the image
				if(this.uploadedRecipeImage !== null || this.uploadedRecipeImage !== undefined || this.uploadedRecipeImage !== ''){
					this.uploadingImage = true;
					var storageRef = Storage.ref();
					var imagesCollectionsRef = storageRef.child(`images/${this.user.data.id}/recipes/${this.uploadedImageFileName}.${this.uploadedRecipeImage.name.split('.').pop()}`);

					var uploadTask = imagesCollectionsRef.put(this.uploadedRecipeImage);
					uploadTask.on('state_changed', 
						(snapshot) => {
							var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							if(progress === 100){
								this.uploadingImage = false;
								this.$emit('showAlert', {text: 'Sucessfully uploaded your image!', type: 'success'});
							}
						}, 
						(error) => {
							this.$emit('showAlert', {text: 'There was an error uploading your image. Check the console.', type: 'error'});
							console.log(error);
						}, 
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
								this.recipe.thumbnail = downloadURL;
								this.$emit('showAlert', {text: 'The file was uploaded successfull.', type: 'success'});
							});
						}
					);
				}
        
        if(this.recipe.steps.length <= 0){
          this.$emit('showAlert', {text: 'Are you even making anything with those steps?', type: 'error'});
          return;
        } else if(this.recipe.steps.length <= 0){
          this.$emit('showAlert', {text: 'This drink ain\'t gonna make itself.', type: 'error'}); 
          return;
        } else {
          // Add recipes to list of stores
          
          // Check to see if pulling from a custom drink or 
          this.recipe.name = (this.drinkModel.name.length >= 2 && this.drinkModel.name != '') ? this.drinkModel.name : this.recipe.name;
          
          this.$emit('showAlert', {text: 'Success! Nice drink you made there!', type: 'success'})
          console.log(this.recipe);
          this.$store.dispatch('addRecipe', this.recipe);
          
          setTimeout(() => {
            this.$router.push('/recipes')
          }, 2000);
        }
        
      }, 
      getEmoji(itemType){
        var searchTerm = ''
        
        if(itemType == null){
          return;
        } else {
          searchTerm = itemType
        }
        
        if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)\b/i.test(searchTerm)){
          return '🥃';
        } 
        
        // Brandy 🍷
        else if(/br\w*nd\w*y\b/i.test(searchTerm)){
          return '🍷';
        }
        
        // Vodka 🥔
        else if(/(vodka)\b/i.test(searchTerm)){
          return '🥔'
        }
        
        // Rum 🍹
        else if(/\b(rh*ums?)\b/i.test(searchTerm)){
          return '🍹';
        }
        
        // Gin 🍸
        else if(/\b(gin)\b/i.test(searchTerm)){
          return '🍸'
        }
        
        // Tequila 🌵
        else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(searchTerm)){
          return '🌵'
        }
        
        // Liqueur 🥂'
        else if(/\b(liq|lic)\w*r\b/i.test(searchTerm)){
          return '🥂'
        } 
        
        else {
          return '🧊'
        }
      },
      getIngredientColor(ingredientType = ''){
        let type = ingredientType != undefined ? ingredientType.toLowerCase() : ''; 
        
        switch(type){ 
          case 'whiskey':
            return 'yellow darken-3';
            break;
          case 'whisky': 
            return 'yellow darken-3';
            break;
          case 'brandy': 
            return 'red';
            break;
          case 'vodka': 
            return 'brown';
            break;
          case 'rum':
            return 'deep-orange';
            break;
          case 'gin':
            return 'green';
            break;
          case 'tequila': 
            return 'light-green';
            break;
          case 'liqueur': 
            return 'teal'; 
            break;
          default: 
            return 'blue-grey';
        }
      }
    }
  }
</script>